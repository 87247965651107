

.calendly-popup {

    @media screen and (max-width: 960px) {
        max-height: 80% !important;
        width: 90%;
        margin: auto;
    }

}


.booking-link {
    background-color: #fff;
    color: #000;
    border: 1px solid #000;
    border-radius: 16px;
    padding: 0.5rem 1rem;
    font-size: 14px;
    font-weight: 600;
    text-decoration: none;
    transition: all 0.2s ease-out;
    margin: 1rem 0;
    display: inline-block;

    &:hover {
        background-color: #FFCA55;
        color: rgb(63, 2, 68);
        border: 1px solid #fff;
        transition: all 0.2s ease-out;
    }
}