#contact-us {
    padding: 50px;
    background: #0b0b5e;
    color: #e7d6d6;
    width: 100%;
    height: auto;
    display: block;

    @media screen and (max-width: 768px) {
        padding-left: 60px;
    }
}

.contact-us-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    // height: 200px;
}

.contact-us-heading {
    font-size: 2.2rem;
    font-weight: 700;
    display: flex;
    justify-content: center;
    color: #ffffff;
    margin-bottom: 3rem;
}

.contact-icon {
    font-size: 2.5rem;
    color: #ffffff;
    margin: 0 1rem 0 0;
}



.contact-us-form {
    display: flex;
    // max-width: 70%;
    width: 100%;
    // flex-wrap: wrap;
    flex-direction: column;
    align-items: flex-start;

    @media screen and (max-width: 768px) {
        width: 100%;
        align-items: center;
    }
}

.label-contact-us-form {
    width: 100%;
    margin-bottom: 0.5rem;
}

.input-contact-us-form {
    width: 100%;
    padding: 0.5rem;
    margin-top: 10px;
    margin-bottom: 30px;
    border: none;
    border-radius: 4px;
    height: 2.5rem;
}

#message {
    width: 100%;
    padding: 0.5rem;
    margin-top: 10px;
    margin-bottom: 30px;
    border: none;
    border-radius: 4px;
    height: 10rem;

    @media screen and (max-width: 768px) {
        max-width: 100%;
        max-height: 10rem;
    }
}

.contact-us-btn {
    width: auto;
    background-color: #FFCA55;
    color: rgb(72, 1, 82);
    padding: 0.5rem 1rem;
    border-radius: 15px;
    cursor: pointer;
    margin-top: 0.2rem;
    height: 40px;
    width: 100px;
    // border: none;

    @media screen and (max-width: 768px) {
        width: 100px;
        height: 40px;
        margin-bottom: 30px;
        // border: none;
    }
}

.contact-us-btn:hover {
    background-color: #fad27a;;
    color: rgb(72, 1, 82);
}

.contact-us-wrapper{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 0px 15rem;
    margin-top: 30px;
    // margin: 0 0 5rem 0;


    @media screen and (max-width: 1200px) {
        padding: 0 1rem;
    }

    @media screen and (max-width: 768px) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0;
    }
}

.contact-us-grid {
    @media screen and (max-width: 1200px) {
        display: flex !important;
        flex-direction: column !important;
        flex-wrap: wrap !important;
        justify-content: center !important;
        align-items: center !important;
        width: 100% !important;
    }
        

}

.contact-us-grid-item-img{
    display: flex;
    justify-content: flex-end;
    padding: 0 !important;

    @media screen and (max-width: 1200px) {
        justify-content: center;
    }
}

.contact-item {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 2rem 0.5rem;

    @media screen and (max-width: 768px) {
        justify-content: flex-start;
        margin: 0;

        margin-bottom: 2rem;
    }
}


.contact-items {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: 100%;
    /* padding: 0 5rem; */
    /* margin: 0 0 5rem 0; */
    justify-content: center;
    margin-top: 20px;

    @media screen and (max-width: 768px) {
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        padding: 0;
        margin-top: 3rem;
    }

}


// .contact-us-grid {
//     height: 100% !important;
// }

.contact-us-grid-item {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    // padding: 0 5rem;
    padding: 0 !important;

}

#contact-us-img {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;

    @media (max-width: 1800px) {
        // margin-left: 5px;
        max-width: 70%;
    }
    @media (max-width: 1000px) {
        // margin-left: 5px;
        max-width: 60%;
    }
}
