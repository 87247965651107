

.hero-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    position: relative;
    z-index: 1;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.01);
    object-fit: contain;
    padding: 0 31px;
    // margin-bottom: 30px;

    video {
        object-fit: cover;
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: -1;
    }

    h1 {
        caret-color: transparent;
        color: #fff;
        font-size: 40px;
        text-align: center;
        margin: 20px 0;

        @media screen and (max-width: 768px) {
            font-size: 40px;
        }

        @media screen and (max-width: 480px) {
            font-size: 25px;
        }
    }

    h2 {
        caret-color: transparent;
        color: #fff;
        font-size: 2rem;
        text-align: center;

        @media screen and (max-width: 768px) {
            font-size: 30px;
        }

        @media screen and (max-width: 480px) {
            font-size: 20px;
        }
    }

    p {
        caret-color: transparent;
        margin: 20px 0;
        color: #fff;
        font-size: 24px;
        text-align: center;
        max-width: 800px;
    
        @media screen and (max-width: 768px) {
            font-size: 16px;
        }
    
        @media screen and (max-width: 480px) {
            font-size: 16px;
        }
    }

}




.btn--outline {
    background-color: transparent;
    color: #fff;
    border: 1px solid #fff;
    width: 17rem;
    transition: all 0.3s ease-out;
    margin-top: 2rem;
}


.about-us-swiper {
    width: 100%;
    // height: 100%;
    // margin: auto;
    .swiper-pagination-bullet-active {
        background-color: white !important;
    }
}

.swiper-slide-about-us {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.highlight-text {
    font-size: inherit;
    // border: #FFCA55;
    color: #FFCA55;
    // border-style: solid;
    // border-width: 0 0 2px 0;
    margin: 0 0 0 0;
    padding: 0 0 0.2rem 0;
    line-height: 2.3rem;
}
