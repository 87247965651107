body {
    font-family: 'Raleway', sans-serif;
    font-size: 14px;
    padding: 0;
    margin: 0;
    // overflow: hidden;
}

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'Raleway', sans-serif;
    caret-color: transparent;
}
