// :root {
//     --primary: #fff;

// }

$--primary: #fff;

.btn {
    padding: 8px 20px;
    border-radius: 2px;
    outline: none;
    border: none;
    cursor: pointer;
}

.btn--primary {
    background-color: $--primary;
    color: #242424;
    border: 1px solid $--primary;
}


.btn--outline {
    background-color: transparent;
    color: $--primary;
    border: 1px solid $--primary;
    // padding: 8px 20px;
    width: 9rem;
    transition: all 0.3s ease-out;
}

.btn--medium {
    padding: 10px 22px;
    font-size: 1.2rem;
}

.btn--large {
    padding: 12px 24px;
    font-size: 1.4rem;
}


.btn--medium:hover, .btn--large:hover {
    background-color: #fff;
    color: #242424;
    // border: 1px solid #fff;
    transition: all 0.3s ease-out;
}
