#services {
    // padding: 30px 31px;
    background: rgb(255, 255, 255);
    width: 100%;
    height: auto;
    display: block;
    padding: 60px 40px;
}

.card_area:hover {
    cursor: auto;
}

.services-parent {
    display: flex;
    flex-direction: column;
    align-items: center;
    // justify-content: center;
    height: 100%;
}

.MuiPaper-root {
    margin: 20px auto;
}

.card-grid {
    display: flex;
    justify-content: center;
}

// .cards-container {
//     margin-bottom: 10px;
// }

.section-title{
    font-size: 2.2rem;
    font-weight: 700;
    color: #242424;
    display: flex;
    justify-content: center;
    color: #881AF2;
    margin-bottom: 40px;
}

@media screen and (max-width: 1500px) {

    #services {
        padding: 40px 20px;
        background: rgb(255, 255, 255);
        width: 100%;
        height: auto;
        display: block;
    }

    .section-title{
        font-size: 2rem;
        font-weight: 700;
        color: #242424;
        display: flex;
        justify-content: center;
        color: #881AF2;
    }
}


@media screen and (max-width: 900px) {

    #services {
        // margin: auto;
        // margin-top: 20px;
        padding: 40px 20px;
        background: rgb(255, 255, 255);
        // color: #242424;
        width: 100%;
        height: fit-content;
        // height: 100vh;
        display: block;
    }
}