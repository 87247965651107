#technologies {
    color: #e7d6d6;
    width: 100%;
    height: 100vh;
    display: block;
    width: 100%;
    height: auto;
    display: flex;
    padding: 40px 0;

    @media (max-width: 1200px) {
        height: 100%;
        display: flex;
    }
}


.technologies-heading {
    font-size: 2.2rem;
    font-weight: 700;
    display: flex;
    justify-content: center;
    color: #881AF2;
    margin-bottom: 3.5rem;
}

.carousel-wrapper {
    display: block;
    margin: auto;
}

.technologies-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 100%;
    height: 100%;

    @media screen and (max-width: 1500px) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        max-width: 100%;
        height: auto;
    }
}

.swiper-slide-technologies {
    background-position: center;
    background-size: cover;
    max-width: 100%;

    @media (max-width: 768px) {
        width: 200px;
        height: auto;
    }
}


.technologies-card{
    background: #fff;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    border-radius: 10px;
    height: 70vh;
    max-width: 500px;
    padding: 18px;
    box-shadow: 0 2px 3px rgba(0,0,0,0.2);
    transition: all 0.2s ease-in-out;
    margin: 10px auto;

    .card-title {
        font-size: 1.4rem;
        margin-bottom: 10px;
        font-weight: 700;
        color: #242424;
        display: flex;
        justify-content: center;
        color: #881AF2;
    }

    &:hover {
        transform: scale(1.02);
        transition: all 0.2s ease-in-out;
        cursor: pointer;
    }
    @media (max-width: 768px) {
        width: 90%;
        height: 600px;
        border-radius: 20px;
        object-fit: cover;

    }

    @media (max-width: 480px) {
        width: 90%;
        height: 380px;
        border-radius: 20px;
        object-fit: cover;
    }
}

.image-list-container {
    width: auto !important;
    height: 600px;
    align-content: space-evenly;

    @media (max-width: 768px) {
        width: 400px;
        height: 600px;
    }

    @media (max-width: 480px) {
        width: 200px;
        height: 600px;
    }
}

.tech-image-list-item{
    object-fit: contain !important;
    border-radius: unset !important;
    height: '100%' !important;
    width: '100%' !important;
    max-width: 100% !important;
    max-height: 100px !important;

}


// Swiper things
.swiper-wrapper {
    margin-bottom: 10px;
}

.swiper-pagination {
    position: absolute !important;
    bottom: -2px !important;
    margin-top: 2px !important;
}

.swiper-pagination-bullet-active {
    background-color: #881AF2 !important;
}

// .swiper-button-prev {
//     background-image: '<i class="fa-light fa-circle-arrow-right"></i>'
// }

// .swiper-button-next {
//     background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%234c71ae'%2F%3E%3C%2Fsvg%3E") !important;
// }