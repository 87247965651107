.home-section {
    // display: flex;
    width: 100%;
    // height: 100vh;
    // overflow-y: scroll;
    // smooth-scrolling
    // scroll-behavior: smooth;
    // overflow-y: scroll;
    // scroll-snap-type: y mandatory;
}

section {
    height: 100vh;
    // scroll-snap-align: center;
}