.subscribe-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 40px 15px;
    height: 600px;
    padding: 20px;

    @media (max-width: 1000px) {
        flex-direction: column;
        height: 100%;
    }

}

.newsletter-prompt-img {
    width: auto;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}

.subscribe-form-grid {

    width: 100%;
    height: 100%;
    padding: 20px;
    display: flex;
    grid-area: inherit;
}

#subscribe-img {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    // margin-left: 10rem;

    @media (max-width: 1000px) {
        margin-left: 5px;
        max-width: 80%;
    }
}

.subscribe-form {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    max-width: 900px;
    caret-color: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0px 0px 10px #0f2af1;
    caret-color: none;


    @media (max-width: 768px) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

}

.subscribe-input {
    caret-color: #510FF1;
    width: 100%;
    // max-width: 80%;
}

.subscribe-title {
    font-size: 2.2rem;
    font-weight: 700;
    display: flex;
    justify-content: flex-start;
    color: #510FF1;
    margin-bottom: 1.5rem;
}

.subscribe-input {
    margin-right: 10px;
    padding: 12px;
    border-radius: 5px;
    border: none;
    background-color: #f1f1f1;
    font-size: 1.2rem;
    transition: all 0.3s ease-in-out;
    flex-grow: 1;

    @media screen and (max-width: 768px) {
        margin-left: 10px;
        margin-bottom: 20px;
        padding: 12px;
        border-radius: 5px;
        border: none;
        background-color: #f1f1f1;
        font-size: 1.2rem;
        transition: all 0.3s ease-in-out;
    }

}

.subscribe-input:focus {
    border: 1px solid #510FF1 !important;
    background-color: #ffffff;
}

.subscribe-input:focus-visible,
.subscribe-input:focus-visible {
    border: 1px solid #510FF1 !important;
    background-color: #ffffff;
    outline: 0px solid #510FF1 !important;
}
/* Button */
.subscribe-btn {
    padding: 12px;
    border-radius: 5px;
    border: none;
    background-color: #510FF1;
    color: #ffffff;
    font-weight: bold;
    font-size: 1.2rem;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
}

.subscribe-btn:hover {
    background-color: #976df8;
}


.newsletter-input-info {
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
}


.subscribe-input {
    &:focus {
        border: 1px solid #510FF1;
        background-color: #ffffff;

    }
}

.subscribe-paragraph {
    font-size: 1.2rem;
    font-weight: 400;
    color: #242424;
    margin-bottom: 1.5rem;
    max-width: 900px;
    line-height: 2rem;
}

.subscribed-paragraph {
    font-size: 1.2rem;
    font-weight: 400;
    color: #242424;
    // margin-bottom: 1.5rem;
    max-width: 900px;
    line-height: 2rem;
}