.navbar {
    caret-color: transparent;
    background: #881AF2;
    // background: linear-gradient(90deg, rgb(255 255 255) 0%, #00001E 100%);
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    // font-size: 1.2rem;
    position: sticky;
    top: 0;
    z-index: 999;

    .navbar-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 80px;
        width: 100%;

        .navbar-logo {
            margin-left: 20px;

            .softmax-logo {
                width: 13rem;
                height: auto;
                margin: 0 0 0 0;
                padding: 0 0 0 0;
                cursor: pointer;
            }
        }
    }

    .nav-links {
        cursor: pointer;
    }

}



.nav-menu {
    display: flex;
    grid-gap: 10px;
    list-style: none;
    text-align: center;
    width: 60vw;
    flex-direction: row;
    justify-content: flex-end;
    margin-right: 2rem;
}

.nav-links {
    color: #fff;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0.5rem 1rem;
    font-size: 14px;
}




.nav-links:hover {
    border-bottom: 4px solid #fff;
    // transition: all 0.2s ease-out;
}

.nav-item .active {
    border-bottom: 4px solid #fff;
    transition: all 0.2s ease-out;
}


.fa-bars {
    color: #fff;
}

.nav-links-mobile {
    display: none;
}

.menu-icon {
    display: none;
}


.btn--outline {
    width: auto !important;
    border-radius: 16px;
}


@media screen and (max-width: 960px) {
    * {
        font-family: 'Raleway', sans-serif;
        font-size: 14px;
        padding: 0;
        margin: 0;
    }


    .nav-menu {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        height: 90vh;
        position: absolute;
        top: 80px;
        left: -100%;
        opacity: 1;
        transition: all 0.5s ease;
    }

    .nav-menu.active {
        background: #00001E;
        left: 0;
        opacity: 1;
        transition: all 0.5s ease;
        z-index: 1;
    }

    .nav-links {
        text-align: center;
        padding: 2rem;
        width: 100%;
        display: table;
    }

    .nav-item .active {
        background-color: #fff;
        color: #242424;
        border-radius: 0;
        transition: all 0.2s ease-out;
    }

    .nav-links:hover {
        background-color: #fff;
        color: #242424;
        border-radius: 0;
    }

    .navbar-logo {
        position: absolute;
        top: -23px;
        left: -50px;
        // height: 100%;
        transform: translate(25%, 50%);
    }

    .menu-icon {
        display: block;
        position: absolute;
        top: 9px;
        right: 0;
        transform: translate(-100%, 60%);
        font-size: 1rem;
        cursor: pointer;
    }

    .fa-times {
        position: absolute;
        top: 17px;
        right: 25px;
        color: #fff;
        font-size: 2rem;
    }

    .nav-links-mobile {
        display: block;
        text-align: center;
        margin: 2rem auto;
        border-radius: 4px;
        width: 80%;
        text-decoration: none;
        font-size: 16px;
        // font-size: 1.5rem;
        background-color: transparent;
        color: #fff;
        padding: 14px 20px;
        border: 1px solid #fff;
        transition: all 0.3s ease-out;
    }

    .nav-links-mobile:hover {
        background: #fff;
        color: #242424;
        transition: 250ms;
    }
}